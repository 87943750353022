import * as React from 'react'
import { Dropdown, Navbar, NavDropdown } from 'react-bootstrap'
import { BsCheckCircle, BsFillXCircleFill } from 'react-icons/bs'
import { Auth } from 'aws-amplify'
import { useHistory } from 'react-router-dom'
import * as API from '../api'

interface Props {
    signed_in: boolean,
    is_admin: boolean,
    case_id: number,
    case_custom_uuid: string,
    nr_of_cases: number,
    api_connection_ok: boolean,
    phase: number,
    with_pft_report: boolean
}

const Header: React.FC<Props> = ({
    signed_in,
    is_admin,
    case_id,
    case_custom_uuid,
    nr_of_cases,
    api_connection_ok,
    phase,
    with_pft_report
}) => {
    let history = useHistory()

    const sign_out = () => {
        Auth.signOut()
            .then(() => history.push('/login'))
    }

    const delete_diagnosis = () => {
        API.delete_diagnosis()
            .then(() => window.location.reload())
    }

    const artiq_pft_report = with_pft_report ? (<span>With <a href="#pft-report" style={{ color: "#006fc4" }}>ArtiQ.PFT Report</a> | </span>) : null

    return (<header>
        <Navbar fixed="top" style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', background: "#ececec" }}>
            <Navbar.Brand href="/" style={{ padding: 10 }}>
                <img src="/logo.png" height="30" alt="ArtiQ logo" />
            </Navbar.Brand>

            <Navbar.Text>
                {case_custom_uuid &&
                    <h4 style={{ margin: 0, color: "black" }}>
                        Survey {phase} | {artiq_pft_report}Case {case_custom_uuid} <small style={{ color: "rgba(0,0,0,.5)" }}>({case_id} / {nr_of_cases})</small>
                    </h4>
                }
            </Navbar.Text>

            <Dropdown alignRight={true}>
                <Dropdown.Toggle style={{ background: 'none', color: 'inherit', border: 'none', padding: 0, font: 'inherit', cursor: 'pointer', outline: 'inherit', textTransform: 'inherit' }}>Settings</Dropdown.Toggle>
                <Dropdown.Menu>
                    {signed_in && (<div>
                        <NavDropdown.Item onClick={sign_out}>Sign out</NavDropdown.Item>
                        <Dropdown.Divider />
                    </div>)}
                    <Dropdown.ItemText>API status: {api_connection_ok ? <BsCheckCircle color="green" /> : <BsFillXCircleFill color="red" />}</Dropdown.ItemText>
                    {is_admin &&
                        <div>
                            <Dropdown.Divider />
                            <NavDropdown.Item onClick={delete_diagnosis}>Delete my diagnosis</NavDropdown.Item>
                        </div>
                    }
                </Dropdown.Menu>
            </Dropdown>{' '}
        </Navbar>
    </header>)
}

export default Header