import * as React from 'react'
import Likert from './likert'
import { Form, FormControl, FormGroup, Button, FormLabel } from 'react-bootstrap'
import MySelect from "./MySelect"
import { OptionsType } from 'react-select'

export interface DiagnosisEntry {
    primary_diagnosis?: string,
    has_differential_diagnosis?: boolean,
    secondary_diagnosis?: string,
    tertiary_diagnosis?: string,
    quaternary_diagnosis?: string,
    confidence_likert?: number,
    next_step?: string,
    investigations?: string,
    timing_of_next_visit?: string,
    comment?: string
}

export const EMPTY_DIAGNOSIS: DiagnosisEntry = {
    primary_diagnosis: '',
    has_differential_diagnosis: undefined,
    secondary_diagnosis: '',
    tertiary_diagnosis: '',
    quaternary_diagnosis: '',
    confidence_likert: 3,
    next_step: '',
    investigations: '',
    timing_of_next_visit: '',
    comment: ''
}

interface Props {
    submit_diagnosis(diagnosis: DiagnosisEntry): void,
    diagnosis_for_case: DiagnosisEntry
}

interface State {
    diagnosis: DiagnosisEntry
}

class Diagnosis extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            diagnosis: props.diagnosis_for_case
        }

        this.handleDiagnosisInputChange = this.handleDiagnosisInputChange.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
    }

    handleDiagnosisInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        const name = event.target.name

        this.setState({
            diagnosis: {
                ...this.state.diagnosis,
                [name]: value
            }
        })
    }

    handleSelectChange(name: string, selected: OptionsType<{ value: string | boolean; label: string; }> | { value: string | boolean; label: string; } | null) {
        const selection = Array.isArray(selected)
            ? selected.map(({ value, _ }) => value).sort().join(',')
            : selected?.value

        this.setState({
            diagnosis: {
                ...this.state.diagnosis,
                [name]: selection
            }
        })
    }

    render() {
        const diagnosis_options = [
            { value: "normal", label: 'Healthy' },
            { value: "asthma", label: 'Asthma' },
            { value: "copd", label: 'COPD - Chronic Obstructive Pulmonary Disease (e.g. chronic bronchitis, emphysema)' },
            { value: "ild", label: 'ILD - Interstitial Lung Disease (e.g. sarcoidosis, IPF, non-IPF)' },
            { value: "nmd", label: 'NMD - Neuromuscular Disease / Diaphragm Paralysis' },
            { value: "obd", label: 'OBD - Other Obstructive Disease (e.g. Cystic fibrosis, bronchiectasis, bronchiolitis)' },
            { value: "pvd", label: 'PVD - Pulmonary Vascular Disease (e.g. pulmonary hypertension)' },
            { value: "td", label: 'TD - Thoracic Deformity (e.g. pneumectomy, chest wall problems, kyphoscoliosis)' },
            { value: "other", label: 'Other - Please specify in the comment section below' }
        ]

        const differential_diagnosis_options = [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' }
        ]

        const next_step_options = [
            { value: "immediate_further_investigation", label: "I plan immediate further investigations" },
            { value: "refer_to_specialist_center", label: "I will refer patient to a specialist center" },
            { value: "start_treatment", label: "I will start treatment without further investigations" },
            { value: "plan_follow_up", label: "I plan a follow-up visit to check the evolution" },
            { value: "send_back_to_gp", label: "I send patient back to GP without follow-up" }
        ]

        const investigations_options = [
            { value: "none", label: "None." },
            { value: "check_medical_history", label: "More profound check of medical history and/or exposure anamnesis" },
            { value: "repeat_spirometry_with_bronchodilator", label: "Repeat spirometry with bronchodilator test" },
            { value: "check_exhaled_no", label: "Check exhaled NO" },
            { value: "perform_methacholine_or_histamine", label: "Perform methacholine/histamine challenge" },
            { value: "perform_spirometry_sitting_lying_down", label: "Perform spirometry sitting and lying down" },
            { value: "perform_echocardiography_and_ecg", label: "Perform echocardiography and ECG" },
            { value: "perform_screening_laboratory", label: "Perform screening laboratory" },
            { value: "perform_x_ray_of_thorax", label: "Perform X-ray of the thorax" },
            { value: "perform_hrct_of_thorax", label: "Perform HRCT of the thorax" },
            { value: "perform_angio_ct_of_thorax", label: "Perform angio-CT of the thorax" },
            { value: "check_hb_and_reliability_of_diffusion_capacity", label: "Check Hb levels and reliability of diffusion capacity test results" },
            { value: "check_maximal_insp_exsp_pressure", label: "Check maximal in- and expiratory pressure" },
            { value: "check_emg_and_rx_of_diaphragm", label: "Check EMG and RX-videography of diaphragm" }
        ]

        const timing_of_next_visit_options = [
            { value: "6_weeks", label: "6 weeks from now" },
            { value: "3_months", label: "3 months from now" },
            { value: "6_months", label: "6 months from now" },
            { value: "1_year", label: "1 year from now" },
            { value: "only_when_complaints_or_no_improvement", label: "Only when the patient complaints get worse or don’t improve" }
        ]

        return (<Form onSubmit={(e) => { e.preventDefault(); this.props.submit_diagnosis(this.state.diagnosis) }}>
            <FormGroup>
                <FormLabel>What is your preferential diagnosis? <span style={{ color: 'red' }}>*</span></FormLabel>
                <MySelect required options={diagnosis_options}
                    onChange={e => this.handleSelectChange('primary_diagnosis', e)} />
            </FormGroup>

            <br></br>
            <FormGroup>
                <FormLabel>How confident do you feel about your decision?</FormLabel>
                <Likert
                    // @ts-expect-error
                    question={null}
                    responses={[
                        { value: 1, text: "Not at all", checked: this.state.diagnosis.confidence_likert === 1 },
                        { value: 2, text: "Slightly", checked: this.state.diagnosis.confidence_likert === 2 },
                        { value: 3, text: "Somewhat", checked: this.state.diagnosis.confidence_likert === 3 },
                        { value: 4, text: "Fairly", checked: this.state.diagnosis.confidence_likert === 4 },
                        { value: 5, text: "Completely", checked: this.state.diagnosis.confidence_likert === 5 }
                    ]}
                    id={"confidence_likert"}
                    onChange={(e: any) => { this.setState({ diagnosis: { ...this.state.diagnosis, confidence_likert: e.value } }) }} />
            </FormGroup>

            <br></br>
            <FormGroup>
                <FormLabel>Do you consider other diseases for differential diagnosis?​ <span style={{ color: 'red' }}>*</span></FormLabel>
                <MySelect required options={differential_diagnosis_options}
                    onChange={e => this.handleSelectChange('has_differential_diagnosis', e)} />
            </FormGroup>

            {this.state.diagnosis.has_differential_diagnosis &&
                <FormGroup>
                    <FormLabel style={{ paddingLeft: '3em' }}>What is the second disease you consider for the differential diagnosis?​</FormLabel>
                    <div style={{ paddingLeft: '3em' }}>
                        <MySelect options={diagnosis_options}
                            onChange={e => this.handleSelectChange('secondary_diagnosis', e)}
                            defaultValue={diagnosis_options.filter(o => o.value === this.state.diagnosis.secondary_diagnosis)} />
                    </div>
                </FormGroup>
            }

            {this.state.diagnosis.has_differential_diagnosis &&
                <FormGroup>
                    <FormLabel style={{ paddingLeft: '3em' }}>What is the third disease you consider for the differential diagnosis?​</FormLabel>
                    <div style={{ paddingLeft: '3em' }}>
                        <MySelect options={diagnosis_options}
                            onChange={e => this.handleSelectChange('tertiary_diagnosis', e)}
                            defaultValue={diagnosis_options.filter(o => o.value === this.state.diagnosis.tertiary_diagnosis)} />
                    </div>
                </FormGroup>
            }

            {this.state.diagnosis.has_differential_diagnosis &&
                <FormGroup>
                    <FormLabel style={{ paddingLeft: '3em' }}>What is the fourth disease you consider for the differential diagnosis?​</FormLabel>
                    <div style={{ paddingLeft: '3em' }}>
                        <MySelect options={diagnosis_options}
                            onChange={e => this.handleSelectChange('quaternary_diagnosis', e)}
                            defaultValue={diagnosis_options.filter(o => o.value === this.state.diagnosis.quaternary_diagnosis)} />
                    </div>
                </FormGroup>
            }

            <br></br>
            <FormGroup>
                <FormLabel>What would be your next step in your daily clinical practice? (Please select all answers that apply.) <span style={{ color: 'red' }}>*</span></FormLabel>
                <MySelect required isMulti options={next_step_options}
                    onChange={e => this.handleSelectChange('next_step', e)}
                />
            </FormGroup>

            {this.state.diagnosis.next_step?.split(',').some(v => ["immediate_further_investigation", "refer_to_specialist_center"].includes(v)) &&
                <div>
                    <br></br>
                    <FormGroup>
                        <FormLabel>What investigations would you immediately request in your daily clinical practice? (Please select all answers that apply.)​ <span style={{ color: 'red' }}>*</span></FormLabel>
                        <MySelect required isMulti options={investigations_options}
                            onChange={e => this.handleSelectChange('investigations', e)}
                            defaultValue={investigations_options.filter(o => this.state.diagnosis.investigations?.split(',').includes(o.value))} />
                    </FormGroup>
                </div>
            }

            {this.state.diagnosis.next_step?.split(',').some(v => ["start_treatment", "plan_follow_up", "send_back_to_gp"].includes(v)) &&
                <div>
                    <br></br>
                    <FormGroup>
                        <FormLabel>When would you plan the next visit with the patient in your daily clinical practice? <span style={{ color: 'red' }}>*</span></FormLabel>
                        <MySelect required options={timing_of_next_visit_options}
                            onChange={e => this.handleSelectChange('timing_of_next_visit', e)}
                            defaultValue={timing_of_next_visit_options.filter(o => o.value === this.state.diagnosis.timing_of_next_visit)} />
                    </FormGroup>
                </div>
            }

            <br></br>
            <FormGroup>
                <FormLabel>Comment</FormLabel>
                <FormControl name="comment" value={this.state.diagnosis.comment} as="textarea" rows={4} placeholder="Write an optional comment here" onChange={this.handleDiagnosisInputChange} />
            </FormGroup>

            <br></br>
            <br></br>
            <FormGroup className="text-center">
                <Button type="submit" variant="primary" style={{ marginLeft: "1.5em", marginRight: "1.5em" }}>
                    Save diagnosis &amp; go to next case
                </Button>
            </FormGroup>
        </Form >)
    }
}

export default Diagnosis
