
import { Spinner } from 'react-bootstrap'

const Loading = () => (
    <div className="justify-content-center"
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
        }}>
        <Spinner animation="border" role="status" />
    </div>
)

export default Loading