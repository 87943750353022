import React, { useState } from 'react'
import { Alert, Col, Container, Row, Spinner } from 'react-bootstrap'
import Case, { CaseItem, EMPTY_CASE } from '../components/Case'
import Diagnosis, { DiagnosisEntry, EMPTY_DIAGNOSIS } from '../components/Diagnosis'
import StickyBox from "react-sticky-box"
import * as API from '../api'
import { useEffect } from 'react'

interface HomeProps {
    current_case: CaseItem,
    set_current_case(c: CaseItem): void,
    nr_of_cases: number,
    set_nr_of_cases(nr: number): void,
    nr_of_cases_done: number,
    set_nr_of_cases_done(nr: number): void
}

function Home({
    current_case,
    set_current_case,
    nr_of_cases,
    set_nr_of_cases,
    nr_of_cases_done,
    set_nr_of_cases_done
}: HomeProps) {
    const [is_loading, set_is_loading] = useState(true)
    const [input_errors, set_input_errors] = useState<string[]>([])
    const [inputs_for_current_case, set_inputs_for_current_case] = useState<DiagnosisEntry>(EMPTY_DIAGNOSIS)

    function load_nr_of_cases_done() {
        API.get_nr_of_cases_done()
            .then(res => set_nr_of_cases_done(res))
    }

    function load_next_case() {
        set_is_loading(true)
        set_input_errors([])

        API.get_next_case()
            .then(res => {
                set_is_loading(false)
                set_current_case(res !== null ? res : JSON.parse(JSON.stringify(EMPTY_CASE)))
                set_inputs_for_current_case(JSON.parse(JSON.stringify(EMPTY_DIAGNOSIS)))
            })
    }

    function submit_diagnosis(diagnosis: DiagnosisEntry) {
        set_is_loading(true)
        set_input_errors([])

        API.store_diagnosis(current_case.id, diagnosis)
            .then(() => {
                console.log("Stored diagnosis.")

                load_next_case()
                load_nr_of_cases_done()
            })
            .catch(error => {
                set_is_loading(false)
                set_input_errors(error.message ? error.message.split(',\n') : [])
            })
    }

    useEffect(() => {
        load_next_case()

        API.get_number_of_cases()
            .then(res => set_nr_of_cases(res))

        load_nr_of_cases_done()
    }, [])


    const alert_thank_you_for_participating = () => {
        return (<Container>
            <Alert variant='success'>You have provided diagnosis to all cases. Thank you for participating.</Alert>
        </Container>)
    }

    const container_case_and_diagnosis = () => {
        return (<Container fluid>
            <Row>
                <Col xs={{ span: 12 }} xl={{ span: 7 }}>
                    <Case
                        case_item={current_case}
                        loading={is_loading}></Case>
                </Col>

                <Col xs={{ span: 10, offset: 1 }} xl={{ span: 4, offset: 0 }}>
                    <StickyBox offsetTop={85} offsetBottom={20}>
                        <h4>Diagnosis</h4>

                        {input_errors.length > 0 &&
                            <Alert variant='danger'>
                                <ul style={{ marginBottom: 0 }}>
                                    {input_errors.map((message, i) => (<li key={i}>{message}</li>))}
                                </ul>
                            </Alert>
                        }

                        {is_loading ?
                            <div className="text-center"><Spinner animation="border" style={{ color: '#005587' }} /></div>
                            :
                            <Diagnosis
                                submit_diagnosis={submit_diagnosis}
                                diagnosis_for_case={inputs_for_current_case}
                                key={current_case.custom_uuid}></Diagnosis>
                        }
                    </StickyBox>
                </Col>
            </Row>
        </Container>)
    }

    if (nr_of_cases_done >= nr_of_cases && nr_of_cases > 0) {
        return alert_thank_you_for_participating()
    } else {
        return container_case_and_diagnosis()
    }
}

export default Home