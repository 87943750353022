import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

import Amplify from 'aws-amplify'
Amplify.configure({
    aws_cognito_region: 'eu-central-1',
    aws_user_pools_id: 'eu-central-1_iTewTq5F0',
    aws_user_pools_web_client_id: '4ssnbg5d4pnh0sajebf69qovr5'
})

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
