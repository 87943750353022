import axios from 'axios'
import { DiagnosisEntry } from './components/Diagnosis'
import { CaseItem } from './components/Case'
import { ConsentInputs } from './pages/Consent'
import { Auth } from 'aws-amplify'
import { TAM2Inputs } from './pages/TAM2'

const API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : ''

const get_auth_header = async () => {
    try {
        const user = await Auth.currentAuthenticatedUser()
        return {
            headers: {
                Authorization: user.signInUserSession.idToken.jwtToken
            }
        }
    } catch (e) {
        return {
            headers: {
                Authorization: null
            }
        }
    }
}

const reject_error = (reject: (reason?: any) => void, error: { response?: any, error?: any }) => {
    if (error.response) {
        if (error.response.data.error) {
            console.error("reject_error error.response.data.error", error.response.data.error)
            reject(error.response.data.error)
        } else {
            console.error("reject_error error.response.data", error.response.data)
            reject(error.response.data)
        }
    } else {
        console.error("reject_error", error)
        reject(error)
    }
}

export function test_api() {
    return new Promise<string>((resolve, reject: (reason?: any) => void) => {
        axios.post(
            API_URL + '/api/test'
        ).then(res => {
            resolve(JSON.stringify(res.data))
        }).catch(error => {
            reject_error(reject, error)
        })
    })
}

export function get_number_of_cases() {
    return new Promise<number>(async (resolve, reject) => {
        axios.get(
            API_URL + '/api/cases/number_of_cases',
            await get_auth_header()
        ).then(res => {
            resolve(res.data)
        }).catch(error => {
            reject_error(reject, error)
        })
    })
}

export function get_nr_of_cases_done() {
    return new Promise<number>(async (resolve, reject) => {
        axios.get(
            API_URL + '/api/cases/number_of_cases_done',
            await get_auth_header()
        ).then(res => {
            resolve(res.data)
        }).catch(error => {
            reject_error(reject, error)
        })
    })
}

export function get_case(case_id: number) {
    return new Promise<CaseItem>(async (resolve, reject) => {
        axios.get(
            API_URL + `/api/cases/${case_id}`,
            await get_auth_header()
        ).then(res => {
            resolve(res.data)
        }).catch(error => {
            reject_error(reject, error)
        })
    })
}

export function get_next_case() {
    return new Promise<CaseItem>(async (resolve, reject) => {
        axios.get(
            API_URL + `/api/cases/get_next_case`,
            await get_auth_header()
        ).then(res => {
            resolve(res.data)
        }).catch(error => {
            reject_error(reject, error)
        })
    })
}

export function store_diagnosis(case_id: number, diagnosis: DiagnosisEntry) {
    return new Promise(async (resolve, reject) => {
        axios.post(
            API_URL + `/api/cases/${case_id}/diagnosis`,
            diagnosis,
            await get_auth_header()
        ).then(res => {
            if (res.data.error) {
                reject_error(reject, res.data.error)
            }
            resolve(true)
        }).catch(error => {
            reject_error(reject, error)
        })
    })
}

export function get_latest_diagnosis_for_case(case_id: number) {
    return new Promise<DiagnosisEntry | null>(async (resolve, reject) => {
        axios.get(
            API_URL + `/api/cases/${case_id}/latest_diagnosis`,
            await get_auth_header()
        ).then(res => {
            resolve(res.data)
        }).catch(error => {
            reject_error(reject, error)
        })
    })
}

export function delete_diagnosis() {
    return new Promise(async (resolve, reject) => {
        axios.post(
            API_URL + `/api/diagnosis/delete_all_for_user`,
            null,
            await get_auth_header()
        ).then(res => {
            resolve(true)
        }).catch(error => {
            reject_error(reject, error)
        })
    })
}

export function get_user_is_admin() {
    return new Promise<boolean>(async (resolve, reject) => {
        axios.get(
            API_URL + `/api/user/is_admin`,
            await get_auth_header()
        ).then(res => {
            resolve(res.data)
        }).catch(error => {
            reject_error(reject, error)
        })
    })
}

export function get_user_has_given_consent() {
    return new Promise<boolean>(async (resolve, reject) => {
        axios.get(
            API_URL + `/api/user/has_given_consent`,
            await get_auth_header()
        ).then(res => {
            console.log("Consent info:", res.data)
            resolve(res.data)
        }).catch(error => {
            reject_error(reject, error)
        })
    })
}

export function post_user_consent_information(consent_info: ConsentInputs) {
    return new Promise<boolean>(async (resolve, reject) => {
        axios.post(
            API_URL + `/api/user/consent`,
            consent_info,
            await get_auth_header()
        ).then(res => {
            if (res.data.error) {
                reject_error(reject, res.data.error)
            }
            console.log("Consent update response:", res.data)
            resolve(res.data)
        }).catch(error => {
            reject_error(reject, error)
        })
    })
}

export function get_user_has_submitted_tam2() {
    return new Promise<boolean>(async (resolve, reject) => {
        axios.get(
            API_URL + `/api/user/has_submitted_tam2`,
            await get_auth_header()
        ).then(res => {
            console.log("TAM2 info:", res.data)
            resolve(res.data)
        }).catch(error => {
            reject_error(reject, error)
        })
    })
}

export function post_tam2(tam2_inputs: TAM2Inputs) {
    return new Promise<boolean>(async (resolve, reject) => {
        axios.post(
            API_URL + `/api/user/tam2`,
            tam2_inputs,
            await get_auth_header()
        ).then(res => {
            if (res.data.error) {
                reject_error(reject, res.data.error)
            }
            console.log("TAM2 update response:", res.data)
            resolve(res.data)
        }).catch(error => {
            reject_error(reject, error)
        })
    })
}
