import * as React from 'react'
import { Row, Col, Container, Spinner, Table, Alert } from 'react-bootstrap'

export interface CaseItem {
    id: number,
    custom_uuid: string,
    age: number,
    gender: string,
    height: number,
    weight: number,
    pack_years: number,
    race: string,
    case_history: string,
    pft_measurements_base64: string,
    pft_curves_base64: string,
    artiq_pft_report_base64?: string,
    phase: number,
    phase_2_activated: boolean
}

export const EMPTY_CASE: CaseItem = {
    id: -1,
    custom_uuid: '',
    age: -1,
    gender: '',
    height: -1,
    weight: -1,
    pack_years: -1,
    race: '',
    case_history: '',
    pft_measurements_base64: '',
    pft_curves_base64: '',
    artiq_pft_report_base64: undefined,
    phase: -1,
    phase_2_activated: false
}

interface Props {
    case_item: CaseItem,
    loading: boolean
}

const Case: React.FC<Props> = ({ case_item, loading }) => {
    const content_max_width = "900px"

    return (<Container fluid>
        {(case_item.phase_2_activated && case_item.phase !== 2) &&
            <Row className="justify-content-center" style={{ marginBottom: "1.5rem" }}>
                <Alert variant="warning">
                    Survey 2 is available but you still have to diagnose the remaining cases from survey 1.
                </Alert>
            </Row>
        }

        <Row style={{ marginBottom: "1.5rem" }}>
            <Col xs={12} xl={{ span: 10, offset: 1 }}>
                <h4>Anamnesis</h4>
                {loading ?
                    <div className="text-center"><Spinner animation="border" style={{ color: '#005587' }} /></div>
                    :
                    <div>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Age</th>
                                    <th>Gender</th>
                                    <th>Height</th>
                                    <th>Weight</th>
                                    <th>Pack-years</th>
                                    <th>Race</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{case_item.age}</td>
                                    <td>{case_item.gender}</td>
                                    <td>{case_item.height}</td>
                                    <td>{case_item.weight}</td>
                                    <td>{case_item.pack_years}</td>
                                    <td>{case_item.race}</td>
                                </tr>
                            </tbody>
                        </Table>

                        <p>{case_item.case_history}</p>
                    </div>
                }
            </Col>
        </Row>

        <Row style={{ marginBottom: "1.5rem" }}>
            <Col xs={12} xl={{ span: 10, offset: 1 }}>
                <h4>Measurements</h4>
                {loading ?
                    <div className="text-center"><Spinner animation="border" style={{ color: '#005587' }} /></div>
                    :
                    <img src={"data:image/png;base64," + case_item.pft_measurements_base64}
                        alt="PFT measurements"
                        style={{ border: '2px solid #c9c9c996', borderRadius: "2%", width: "100%", maxWidth: content_max_width }} />
                }
            </Col>
        </Row>

        <Row style={{ marginBottom: "1.5rem" }}>
            <Col xs={12} xl={{ span: 10, offset: 1 }}>
                <h4>Curves</h4>
                {loading ?
                    <div className="text-center"><Spinner animation="border" style={{ color: '#005587' }} /></div>
                    :
                    <img src={"data:image/png;base64," + case_item.pft_curves_base64}
                        alt="PFT curves"
                        style={{ border: '2px solid #c9c9c996', borderRadius: "2%", width: "100%", maxWidth: content_max_width }} />
                }
            </Col>
        </Row>

        {case_item.artiq_pft_report_base64 &&
            <Row style={{ marginBottom: "1.5rem" }} id="pft-report">
                <Col xs={12} xl={{ span: 10, offset: 1 }}>
                    <h4>ArtiQ.PFT Report</h4>
                    {loading ?
                        <div className="text-center"><Spinner animation="border" style={{ color: '#005587' }} /></div>
                        :
                        <img src={"data:image/png;base64," + case_item.artiq_pft_report_base64}
                            alt="ArtiQ.PFT report"
                            style={{ border: '2px solid #c9c9c996', borderRadius: "2%", width: "100%", maxWidth: content_max_width }} />
                    }
                </Col>
            </Row>
        }
    </Container>)
}

export default Case