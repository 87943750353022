import { Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'

const NotFound = () => (
    <Container>
        <h1>
            404 - Page not found!
        </h1>
        <Link to='/'>Go to homepage</Link>
    </Container>
)

export default NotFound