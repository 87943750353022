import React from 'react'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import Header from './components/Header'
import { CaseItem, EMPTY_CASE } from './components/Case'
import * as API from './api'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import NotFound from './pages/NotFound'
import Consent from './pages/Consent'
import Login from './pages/Login'
import Home from './pages/Home'
import TAM2 from './pages/TAM2'
import Loading from './pages/Loading'

interface State {
    loading: boolean,
    user: any,
    signed_in: boolean,
    consent_given: boolean,
    is_admin: boolean,
    current_case: CaseItem,
    nr_of_cases: number,
    nr_of_cases_done: number,
    api_connection_ok: boolean,
    has_submitted_tam2: boolean
}

class App extends React.Component<{}, State> {
    constructor(props: {}) {
        super(props)

        this.state = {
            loading: false,
            user: undefined,
            signed_in: false,
            consent_given: false,
            is_admin: false,
            current_case: EMPTY_CASE,
            nr_of_cases: -1,
            nr_of_cases_done: -1,
            api_connection_ok: false,
            has_submitted_tam2: false
        }
    }

    async componentDidMount() {
        onAuthUIStateChange((next_auth_state, cognito_user) => {
            if (next_auth_state === AuthState.SignedIn) {
                this.setState({ loading: true })
                
                Promise.all([
                    API.get_user_has_given_consent().then(res => this.setState({ consent_given: res })),
                    API.get_user_has_submitted_tam2().then(res => this.setState({ has_submitted_tam2: res })),
                    API.get_user_is_admin().then(res => this.setState({ is_admin: res }))]
                )
                .then(_ => this.setState({ loading: false }))
            }

            this.setState({
                signed_in: next_auth_state === AuthState.SignedIn,
                user: cognito_user
            })
        })

        API.test_api()
            .then(_ => this.setState({ api_connection_ok: true }))
    }

    getPageToRender() {
        if (!this.state.signed_in) {
           return <Login />
        }
        if (!this.state.consent_given) {
            return <Consent set_consent_given={(c) => this.setState({ consent_given: c })} />
        }
        if (this.state.current_case?.phase === 2 && !this.state.has_submitted_tam2) {
            return <TAM2 set_tam2_submitted={(c) => this.setState({ has_submitted_tam2: c })} />
        }

        return <Home
        current_case={this.state.current_case}
        set_current_case={(c: CaseItem) => this.setState({ current_case: c })}
        nr_of_cases={this.state.nr_of_cases}
        set_nr_of_cases={(n: number) => this.setState({ nr_of_cases: n })}
        nr_of_cases_done={this.state.nr_of_cases_done}
        set_nr_of_cases_done={(n: number) => this.setState({ nr_of_cases_done: n })} />

    }
    render() {
        return (<Router>
            {this.state.signed_in &&
                // Don't show header on login page because genius amplify UI puts the errors in absolute
                // position behind the header. Amplify UI components are under shadow root so you can barely control them.
                <Header
                    signed_in={this.state.signed_in}
                    is_admin={this.state.is_admin}
                    case_id={this.state.current_case.id}
                    case_custom_uuid={this.state.current_case.custom_uuid}
                    nr_of_cases={this.state.nr_of_cases}
                    api_connection_ok={this.state.api_connection_ok}
                    phase={this.state.current_case.phase}
                    with_pft_report={this.state.current_case.artiq_pft_report_base64 ? true : false} />}

            <div style={{ paddingTop: this.state.signed_in ? '5.5rem' : '0' }}>
                <Switch>
                    <Route exact path='/'>
                        {this.state.loading
                            ? <Loading />
                            : this.getPageToRender()}
                    </Route>
                    <Route path='*' component={NotFound} />
                </Switch>
            </div >
        </Router >)
    }
}

export default App
