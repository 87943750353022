import { FormEvent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Container, Row, Col, Alert } from 'react-bootstrap'
import { Form, FormGroup, Button, FormLabel } from 'react-bootstrap'
import Likert from '../components/likert'
import * as API from '../api'

export interface TAM2Inputs {
    intend_to_use_it: number
    predict_to_use_it: number
    improves_my_performance: number
    increases_my_productivity: number
    enhances_my_effectiveness: number
    useful_in_my_job: number
    clear_and_understandable: number
    not_a_lot_of_mental_effort: number
    easy_to_use: number
    does_what_i_want: number
    important_in_my_job: number
    relevant_in_my_job: number
    high_quality_output: number
    no_problem_with_quality: number
    no_difficulty_telling_others: number
    could_communicate_to_others: number
    apparent_to_me: number,
    difficulty_explaining: number
}

interface questionItem {
    id: keyof TAM2Inputs
    label: string
}

interface Props {
    set_tam2_submitted(c: boolean): void
}

function TAM2({ set_tam2_submitted }: Props) {
    const [tam2_inputs, set_tam2_inputs] = useState<TAM2Inputs>({
        intend_to_use_it: -1,
        predict_to_use_it: -1,
        improves_my_performance: -1,
        increases_my_productivity: -1,
        enhances_my_effectiveness: -1,
        useful_in_my_job: -1,
        clear_and_understandable: -1,
        not_a_lot_of_mental_effort: -1,
        easy_to_use: -1,
        does_what_i_want: -1,
        important_in_my_job: -1,
        relevant_in_my_job: -1,
        high_quality_output: -1,
        no_problem_with_quality: -1,
        no_difficulty_telling_others: -1,
        could_communicate_to_others: -1,
        apparent_to_me: -1,
        difficulty_explaining: -1
    })
    const [input_errors, set_input_errors] = useState<string[]>([])
    let history = useHistory()

    function submit_tam2() {
        const keys = Object.keys(tam2_inputs)
        for (let i = 0; i < keys.length; i += 1) {
            const key = keys[i] as keyof TAM2Inputs
            if (tam2_inputs[key] === -1) {
                set_input_errors(["Please answer all questions"])
                document.getElementById(key)?.scrollIntoView();
                window.scrollBy(0, -100);
                return;
            }
        }

        API.post_tam2(tam2_inputs)
            .then(() => {
                console.log("Stored TAM2 information.")
                set_tam2_submitted(true)
                history.push('/')
            })
            .catch(error => {
                set_input_errors(error.message ? error.message.split(',\n') : error)
                window.scrollTo(0, 0)
            })
    }

    const questions_data: questionItem[] = [
        {
            id: 'intend_to_use_it',
            label: 'Assuming I have access to the system. I intend to use it.'
        },
        {
            id: 'predict_to_use_it',
            label: 'Given that I have access to the system, I predict that I would use it.'
        },
        {
            id: 'improves_my_performance',
            label: 'Using the system improves my performance in my job.'
        },
        {
            id: 'increases_my_productivity',
            label: 'Using the system in my job increases my productivity.'
        },
        {
            id: 'enhances_my_effectiveness',
            label: 'Using the system enhances my effectiveness in my job.'
        },
        {
            id: 'useful_in_my_job',
            label: 'I find the system to be useful in my job.'
        },
        {
            id: 'clear_and_understandable',
            label: 'My interaction with the system is clear and understandable.'
        },
        {
            id: 'not_a_lot_of_mental_effort',
            label: 'Interacting with the system does not require a lot of my mental effort.'
        },
        {
            id: 'easy_to_use',
            label: 'I find the system easy to use.'
        },
        {
            id: 'does_what_i_want',
            label: 'I find it easy to get the system to do what I a want to do.'
        },
        {
            id: 'important_in_my_job',
            label: 'In my job, usage of the system is important.'
        },
        {
            id: 'relevant_in_my_job',
            label: 'In my job, usage of the system is relevant.'
        },
        {
            id: 'high_quality_output',
            label: 'The quality of the output I get from the system is high.'
        },
        {
            id: 'no_problem_with_quality',
            label: 'I have no problem with the quality of the system\'s output.'
        },
        {
            id: 'no_difficulty_telling_others',
            label: 'I have no difficulty telling others about the results of using the system.'
        },
        {
            id: 'could_communicate_to_others',
            label: 'I believe I could communicate to others the consequences of using the system.'
        },
        {
            id: 'apparent_to_me',
            label: 'The results of using the system are apparent to me.'
        },
        {
            id: 'difficulty_explaining',
            label: 'I would have difficulty explaining why using the system may or may not be beneficial.'
        },
    ]

    function questions(): JSX.Element[] {
        return questions_data.map((data: questionItem) =>
            <div key={data.id}>
                <br/>
                <br />
                <FormGroup>
                    <FormLabel>{data.label} <span style={{ color: 'red' }}>*</span></FormLabel>
                    <Likert
                        // @ts-expect-error
                        id={data.id}
                        question={null}
                        responses={[
                            { value: '1', text: 'Strongly disagree.', checked: tam2_inputs[data.id] === 1 },
                            { value: '2', text: 'Moderately disagree.', checked: tam2_inputs[data.id] === 2 },
                            { value: '3', text: 'Somewhat disagree.', checked: tam2_inputs[data.id] === 3 },
                            { value: '4', text: 'Neutral (neither disagree nor agree).', checked: tam2_inputs[data.id] === 4 },
                            { value: '5', text: 'Somewhat agree.', checked: tam2_inputs[data.id] === 5 },
                            { value: '6', text: 'Moderately agree.', checked: tam2_inputs[data.id] === 6 },
                            { value: '7', text: 'Strongly agree.', checked: tam2_inputs[data.id] === 7 },
                        ]}
                        onChange={(e: any) => { set_tam2_inputs({...tam2_inputs, [data.id]: +e.value}); }} />
                </FormGroup>
            </div>)
    }

    return (<Container><Row><Col md={{ span: 10, offset: 1 }}>
        {
            input_errors.length > 0 &&
            <Alert variant='danger'>
                <ul style={{ marginBottom: 0 }}>
                    {input_errors.map((message, i) => (<li key={i}>{message}</li>))}
                </ul>
            </Alert>
        }

        <Form onSubmit={(e: FormEvent) => { e.preventDefault(); submit_tam2() }}>
            <h3>
                TAM2 Questionnaire
            </h3>
            <p>
                Thank you for your participation in this Research Project. Please fill the questionnaire below.
            </p>
            <p>
                If you have any questions, concerns or suggestions, please reach out via <a href="mailto:support@artiq.eu">support@artiq.eu</a>.
            </p>

            {questions()}

            <br />
            <br />
            <FormGroup className="text-center">
                <Button type="submit" variant="primary" style={{ marginLeft: "1.5em", marginRight: "1.5em" }}>
                    Submit and continue study
                </Button>
            </FormGroup>
            <br />
            <br />
            <br />
            <br />
        </Form >
    </Col></Row></Container>)
}

export default TAM2
