import { FormEvent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Container, Row, Col, Alert } from 'react-bootstrap'
import { Form, FormGroup, Button, FormLabel, FormCheck, FormControl } from 'react-bootstrap'
import MySelect from "../components/MySelect"
import Likert from '../components/likert'
import * as API from '../api'

export interface ConsentInputs {
    years_of_experience: string,
    subspecialty: string,
    has_worked_with_ai_before: boolean,
    enthusiasm_about_ai_likert: number,
    consent_given: boolean
}

interface Props {
    set_consent_given(c: boolean): void
}

function Consent({ set_consent_given }: Props) {
    const [consent_inputs, set_consent_inputs] = useState<ConsentInputs>({
        years_of_experience: '',
        subspecialty: '',
        has_worked_with_ai_before: false,
        enthusiasm_about_ai_likert: 3,
        consent_given: false,
    })
    const [input_errors, set_input_errors] = useState<string[]>([])
    let history = useHistory()

    function submitConsent() {
        API.post_user_consent_information(consent_inputs)
            .then(() => {
                console.log("Stored consent information.")
                set_consent_given(true)
                history.push('/')
            })
            .catch(error => {
                set_input_errors(error.message ? error.message.split(',\n') : error)
                window.scrollTo(0, 0)
            })
    }

    const years_of_experience_options = [
        { value: "training", label: 'Pulmonologist in training' },
        { value: "one_to_five", label: 'Pulmonologist for less than 5 years' },
        { value: "five_to_ten", label: 'Pulmonologist for 5 to 10 years' },
        { value: "more_than_ten", label: 'Pulmonologist for more than 10 years' }
    ]

    return (<Container><Row><Col md={{ span: 10, offset: 1 }}>
        {
            input_errors.length > 0 &&
            <Alert variant='danger'>
                <ul style={{ marginBottom: 0 }}>
                    {input_errors.map((message, i) => (<li key={i}>{message}</li>))}
                </ul>
            </Alert>
        }

        <Form onSubmit={(e: FormEvent) => { e.preventDefault(); submitConsent() }}>
            <h3>
                Consent
            </h3>
            <p>
                Thank you for your participation in this Research Project. Please read the document below before providing your consent.
            </p>
            <p>
                If you have any questions, concerns or suggestions, please reach out via <a href="mailto:support@artiq.eu">support@artiq.eu</a>.
            </p>

            <p>
                Click <a target="_blank" rel="noopener noreferrer" href="/ArtiQ_GDPR_consent.pdf">here</a> to open the document in a new page.
            </p>

            <object data="ArtiQ_GDPR_consent.pdf"
                type="application/pdf"
                aria-label="Informed Consent Form"
                style={{ height: "800px", width: "100%" }}></object>

            <br></br>
            <br></br>
            <FormGroup>
                <FormCheck required type="checkbox" id="consent_checkbox"
                    onChange={e => set_consent_inputs({ ...consent_inputs, consent_given: e.target.checked })}
                    label="I declare that I have read and understood what is set out above and I consent to the processing of my personal data in the framework of the Research Study, and the further use of my data in anonymous and aggregated form." />
            </FormGroup>

            <br></br>
            <br></br>
            <h3>
                About you
            </h3>
            <p>
                Please answer the following questions about you.
            </p>

            <FormGroup>
                <FormLabel>What is your experience as a pulmonologist? <span style={{ color: 'red' }}>*</span></FormLabel>
                <MySelect required options={years_of_experience_options}
                    onChange={e => set_consent_inputs({ ...consent_inputs, years_of_experience: e !== null ? e.value : '' })} />
            </FormGroup>

            <br></br>
            <FormGroup>
                <FormLabel>What is your subspecialty within respiratory medicine?</FormLabel>
                <FormControl as="input" placeholder="e.g. COPD, oncology..." onChange={e => set_consent_inputs({ ...consent_inputs, subspecialty: e.target.value })} />
            </FormGroup>

            <br></br>
            <FormGroup>
                <FormLabel>Have you worked with AI-based decision support systems before? <span style={{ color: 'red' }}>*</span></FormLabel>
                <div>
                    <FormCheck required inline name="has_worked_with_ai_before" label="Yes" type="radio" id="has_worked_with_ai_before_checkbox_yes" onClick={() => set_consent_inputs({ ...consent_inputs, has_worked_with_ai_before: true })} />
                    <FormCheck required inline name="has_worked_with_ai_before" label="No" type="radio" id="has_worked_with_ai_before_checkbox_no" onClick={() => set_consent_inputs({ ...consent_inputs, has_worked_with_ai_before: false })} />
                </div>
            </FormGroup>

            <br></br>
            <FormGroup>
                <FormLabel>How enthusiastic are you about AI for clinical outcomes in general? <span style={{ color: 'red' }}>*</span></FormLabel>
                <Likert
                    // @ts-expect-error
                    question={null}
                    responses={[
                        { value: 1, text: "Not at all", checked: consent_inputs.enthusiasm_about_ai_likert === 1 },
                        { value: 2, text: "Slightly", checked: consent_inputs.enthusiasm_about_ai_likert === 2 },
                        { value: 3, text: "Neutral", checked: consent_inputs.enthusiasm_about_ai_likert === 3 },
                        { value: 4, text: "Fairly", checked: consent_inputs.enthusiasm_about_ai_likert === 4 },
                        { value: 5, text: "Very", checked: consent_inputs.enthusiasm_about_ai_likert === 5 }
                    ]}
                    id={"confidence_likert"}
                    onChange={(e: any) => { set_consent_inputs({ ...consent_inputs, enthusiasm_about_ai_likert: e.value }) }} />
            </FormGroup>

            <br></br>
            <br></br>
            <FormGroup className="text-center">
                <Button type="submit" variant="primary" style={{ marginLeft: "1.5em", marginRight: "1.5em" }}>
                    Submit and start study
                </Button>
            </FormGroup>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
        </Form >
    </Col></Row></Container>)
}

export default Consent
