import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react'

function Login() {
    return (
        <AmplifyAuthenticator>
            <AmplifySignIn slot="sign-in" hideSignUp></AmplifySignIn >
        </AmplifyAuthenticator >
    )
}

export default Login