import React from "react"
import Select from "react-select"
import { Props as SelectProps } from "react-select"

// Wrapper for react-select's Select that includes support for making the input required.
class MySelect extends React.Component<SelectProps, { value: any, select_react_ref: any }> {
    constructor(props: SelectProps) {
        super(props)
        this.state = {
            value: this.props.value || "",
            select_react_ref: null
        }
    }

    set_select_ref = (ref: any) => {
        this.setState({ select_react_ref: ref })
    }

    onChange = (value: any, action: any) => {
        if (this.props.onChange) {
            this.props.onChange(value, action)
        }

        this.setState({ value })
    }

    getValue = () => {
        let val

        if (this.props.value !== undefined) {
            val = this.props.value
        } else if (this.props.defaultValue !== undefined) {
            val = this.props.defaultValue
        } else {
            val = this.state.value || ""
        }

        if ((Array.isArray(val) && val.length > 0) || (val instanceof Object && Object.keys(val).length > 0)) {
            return JSON.stringify(val)
        }

        return val
    }

    render() {
        const { required, ...props } = this.props
        props.isClearable = props.isClearable === undefined ? false : props.isClearable
        props.isSearchable = props.isSearchable === undefined ? false : props.isSearchable

        return (
            <div>
                <Select
                    {...props}
                    ref={this.set_select_ref}
                    onChange={this.onChange}
                />
                {!this.props.isDisabled && (
                    <input
                        tabIndex={-1}
                        autoComplete="off"
                        style={{
                            opacity: 0,
                            width: "100%",
                            height: 0,
                            position: "absolute"
                        }}
                        value={this.getValue()}
                        onChange={() => { }}
                        onFocus={() => this.state.select_react_ref.focus()}
                        required={required}
                    />
                )}
            </div>
        )
    }
}

export default MySelect
